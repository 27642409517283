import axios from 'axios';

import { BucketType } from './documentHandler';
import { downloadFile } from './downloadFile';
import { extractFileKeyFromUrl } from './urlUtils';

export class DocumentUpload {
  static async createDocument(
    file: File,
    documentType: BucketType
  ): Promise<{ documentUrl: string; fileName: string }> {
    const fileName = encodeURIComponent(file.name.replaceAll(' ', '_')); //
    const fileType = encodeURIComponent(file.type);
    const res = await axios.get(
      `/api/documents?operationType=createPresignedPost&file=${fileName}&fileType=${fileType}&documentType=${documentType}`
    );
    const { url, fields } = await res.data;
    const formData = new FormData();

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    await axios.post(url, formData);
    return {
      documentUrl: url + fields.key,
      fileName,
    };
  }

  static async downloadDocument(
    documentUrl: string,
    fileName: string,
    documentType: BucketType
  ) {
    const key = extractFileKeyFromUrl(documentUrl);

    const res = await axios.get(
      `/api/documents?operationType=getSignedUrl&key=${key}&documentType=${documentType}`
    );

    downloadFile(res.data.url, fileName);
  }
}
