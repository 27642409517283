import { DateUtilInstance, getNow } from '@zorro/shared/formatters';
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  InternalAxiosRequestConfig,
} from 'axios';
import _truncate from 'lodash/truncate';
import pino from 'pino';

import { assertAccessToken } from './echoPayments.assertions';
import { CLIENT_ERRORS, OMISSION_CHAR } from './echoPayments.consts';
import {
  EchoPaymentsClientError,
  isExpiredAccessTokenError,
  isIPRestrictedError,
  isInvalidAccessTokenError,
  logAxiosError,
} from './echoPayments.errors';
import {
  GetTokenRequestHeaders,
  GetTokenResponse,
  PaymentAuthenticatedRequestHeaders,
  PaymentEnrollRequestPayload,
  PaymentEnrollResponse,
  PaymentInquiryRequestPayload,
  PaymentInquiryResponse,
} from './echoPayments.types';
import { backoffDelay } from './echoPayments.utils';

type EchoPaymentsClientConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
};

export class EchoPaymentsClient {
  private readonly httpClient: AxiosInstance;

  private accessToken: string | null = null;
  private accessTokenExpiry: DateUtilInstance | null = null;

  // the API spec denotes 30min, we chose 15min to be on the safe side
  private readonly EXPIRY_IN_MIN = 15;

  private readonly MAX_RETRIES = 2;

  constructor(
    httpClientProvider: AxiosStatic,
    private loggerProvider: () => pino.Logger,
    private readonly config: EchoPaymentsClientConfig
  ) {
    const { baseUrl } = config;
    this.httpClient = httpClientProvider.create({
      baseURL: baseUrl,
    });

    this.setupInterceptors();
  }

  private get logger() {
    return this.loggerProvider();
  }

  private setupInterceptors() {
    this.httpClient.interceptors.request.use(this.logRequest);
    this.httpClient.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  }

  private logRequest = (config: InternalAxiosRequestConfig) => {
    if (config.url !== '/api/v1/GetToken') {
      this.logger.info(
        {
          headers: config.headers,
          data: config.data,
        },
        `Request: ${config.method?.toUpperCase()} ${config.url}`
      );
    }
    return config;
  };

  /**
   * Handle Response
   *
   * Status codes within the 2xx range
   */
  private handleResponse = async (response: AxiosResponse) => {
    if (response.config.url !== '/api/v1/GetToken') {
      this.logger.info(
        {
          headers: response.headers,
          data: response.data,
        },
        `Response: ${response.status} ${response.config.url}`
      );
    }
    const { data } = response;
    if (isInvalidAccessTokenError(data)) {
      throw new EchoPaymentsClientError(CLIENT_ERRORS.INVALID_ACCESS_TOKEN);
    }
    if (isExpiredAccessTokenError(data)) {
      throw new EchoPaymentsClientError(CLIENT_ERRORS.EXPIRED_ACCESS_TOKEN);
    }
    return response;
  };

  /**
   * Handle Error
   *
   * Status codes outside the 2xx range
   */
  private handleError = async (error: AxiosError) => {
    if (isIPRestrictedError(error)) {
      const { config: originalRequest } = error;
      if (originalRequest) {
        throw new EchoPaymentsClientError(
          `${CLIENT_ERRORS.IP_RESTRICTED} [${originalRequest.baseURL}]`
        );
      }
    }
    throw error;
  };

  private async fetchAccessToken(retries = this.MAX_RETRIES): Promise<string> {
    try {
      const headers: GetTokenRequestHeaders = {
        'x-ApiKey': this.config.clientId,
        'x-ClientKey': this.config.clientSecret,
      };
      const request: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/v1/GetToken',
        headers,
      };

      const { data } = await this.httpClient<GetTokenResponse>(request);
      assertAccessToken(data);

      this.accessToken = data.TransLog[0].AuthToken;
      this.accessTokenExpiry = getNow().add(this.EXPIRY_IN_MIN, 'minute');

      return this.accessToken;
    } catch (error) {
      logAxiosError(this.logger, error);

      if (retries > 0) {
        const retryIdx = this.MAX_RETRIES - retries;
        const retryCount = `${retryIdx + 1}/${this.MAX_RETRIES}`;
        this.logger.info(`fetchAccessToken retry: ${retryCount}`);
        await backoffDelay(retryIdx);
        return this.fetchAccessToken(retries - 1);
      }

      throw new EchoPaymentsClientError(
        `fetchAccessToken error: ${error.message}`
      );
    }
  }

  private async getAccessToken() {
    if (
      this.accessToken &&
      this.accessTokenExpiry &&
      getNow().isBefore(this.accessTokenExpiry)
    ) {
      return this.accessToken;
    }
    return this.fetchAccessToken();
  }

  private async post<TRequestData, TResponse>(requestData: TRequestData) {
    const accessToken = await this.getAccessToken();

    const headers: PaymentAuthenticatedRequestHeaders = {
      'x-Authorization': accessToken,
      'Content-Type': 'application/json',
    };

    const request: AxiosRequestConfig = {
      method: 'POST',
      url: '/api/PPM/v1/Portal',
      headers,
      data: requestData,
    };

    const { data } = await this.httpClient<TResponse>(request);

    return data;
  }

  async getPaymentDetails(
    payload: Omit<PaymentInquiryRequestPayload, 'APIFormIdentifierID'>
  ): Promise<PaymentInquiryResponse> {
    try {
      const requestData: PaymentInquiryRequestPayload = {
        APIFormIdentifierID: 250001,
        ...payload,
      };

      // ❓ should we handle "No payment details found for the provided Effective from date." in a specific manner
      //    i.e. instead of returning not found
      //    ResponseCode 900
      return await this.post<
        PaymentInquiryRequestPayload,
        PaymentInquiryResponse
      >(requestData);
    } catch (error) {
      logAxiosError(this.logger, error);
      throw new EchoPaymentsClientError(
        `getPaymentDetails error: ${error.message}`
      );
    }
  }

  async createPaymentMethod(
    payload: Omit<PaymentEnrollRequestPayload, 'APIFormIdentifierID'>
  ): Promise<PaymentEnrollResponse> {
    try {
      const requestData: PaymentEnrollRequestPayload = {
        APIFormIdentifierID: 250002,
        ...payload,
        EmployeeUniqueID: _truncate(payload.EmployeeUniqueID, {
          length: 20,
          omission: OMISSION_CHAR,
        }),
        EmployeeFirstName: _truncate(payload.EmployeeFirstName, {
          length: 30,
          omission: OMISSION_CHAR,
        }),
        EmployeeLastName: _truncate(payload.EmployeeLastName, {
          length: 30,
          omission: OMISSION_CHAR,
        }),
        Plan: _truncate(payload.Plan, {
          length: 30,
          omission: OMISSION_CHAR,
        }),
      };

      return this.post<PaymentEnrollRequestPayload, PaymentEnrollResponse>(
        requestData
      );
    } catch (error) {
      logAxiosError(this.logger, error);
      throw new EchoPaymentsClientError(
        `createPaymentMethod error: ${error.message}`
      );
    }
  }
}
