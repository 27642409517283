import { AnticipatedCareLevel, Gender } from '@zorro/clients';
import {
  earliestAllowedNonDependentBirthDate,
  latestAllowedNonDependentBirthDate,
} from '@zorro/shared/formatters';
import * as yup from 'yup';

import { ssnRegex } from '../ssn';
import { VALIDATION_MESSAGES } from '../validationMessages';

export const allowedChildDateOfBirth = {
  dateOfBirth: yup
    .date()
    .typeError(VALIDATION_MESSAGES.dateOfBirthRequired)
    .required(VALIDATION_MESSAGES.dateOfBirthRequired),
};

export const allowedNonChildDateOfBirth = {
  dateOfBirth: yup
    .date()
    .min(
      latestAllowedNonDependentBirthDate(),
      VALIDATION_MESSAGES.nonDependentMaxAgeValid
    )
    .max(
      earliestAllowedNonDependentBirthDate(),
      VALIDATION_MESSAGES.nonDependentMinAgeValid
    )
    .typeError(VALIDATION_MESSAGES.dateOfBirthRequired)
    .required(VALIDATION_MESSAGES.dateOfBirthRequired),
};

export const familyMemberSchema = (isChild = false) => ({
  firstName: yup
    .string()
    .required(VALIDATION_MESSAGES.firstNameRequired)
    .min(2, VALIDATION_MESSAGES.firstNameMinTwoLetters),
  lastName: yup
    .string()
    .required(VALIDATION_MESSAGES.lastNameRequired)
    .min(2, VALIDATION_MESSAGES.lastNameMinTwoLetters),
  ...(isChild ? allowedChildDateOfBirth : allowedNonChildDateOfBirth),
  gender: yup
    .string()
    .oneOf(Object.keys(Gender), VALIDATION_MESSAGES.genderRequired)
    .required(VALIDATION_MESSAGES.genderRequired),
  address: yup.string().required(VALIDATION_MESSAGES.addressRequired),
  isSmoker: yup.string().required(VALIDATION_MESSAGES.smokerRequired),
  isPregnant: yup.string().when('gender', {
    is: Gender.FEMALE,
    then: (schema) => schema.required(VALIDATION_MESSAGES.pregnantRequired),
    otherwise: (schema) => schema.notRequired(),
  }),
  ssn: yup
    .string()
    .required(VALIDATION_MESSAGES.ssnRequired)
    .matches(ssnRegex, VALIDATION_MESSAGES.ssnNumberValid),
});

enum InsuredTypes {
  DEPENDENT = 'Dependent',
}
export const familyMemberAddSchema = () => ({
  ...familyMemberSchema(),
  dateOfBirth: yup.string().when('insuredType', {
    is: InsuredTypes.DEPENDENT,
    // See more: https://github.com/sindresorhus/eslint-plugin-unicorn/issues/1710
    then: () => allowedChildDateOfBirth.dateOfBirth,
    otherwise: () => allowedNonChildDateOfBirth.dateOfBirth,
  }),
});

export const employeeSchema = {
  ...familyMemberSchema(),
  personalEmail: yup
    .string()
    .email(VALIDATION_MESSAGES.personalEmailValid)
    // eslint-disable-next-line require-unicode-regexp
    .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
      message: VALIDATION_MESSAGES.personalEmailValid,
    })
    .typeError(VALIDATION_MESSAGES.personalEmailRequired)
    .required(VALIDATION_MESSAGES.personalEmailRequired),
  phone: yup
    .string()
    .typeError(VALIDATION_MESSAGES.phoneNumberRequired)
    .required(VALIDATION_MESSAGES.phoneNumberRequired)
    /**
     * INFO: we expect a 10-digit US phone number entered by the user
     * and react-phone-number-input automatically adds +1 prefix to it
     */
    .matches(/^\+?\d{11}$/u, VALIDATION_MESSAGES.phoneNumberValid),
};

const employeeSchemaForAdmin = {
  ...employeeSchema,
  personalEmail: yup.lazy((value) =>
    value
      ? yup.string().email(VALIDATION_MESSAGES.emailValid)
      : yup.string().notRequired()
  ),
  phone: yup
    .string()
    .typeError(VALIDATION_MESSAGES.phoneNumberValid)
    .notRequired()
    /**
     * INFO: we expect a 10-digit US phone number entered by the user
     * and react-phone-number-input automatically adds +1 prefix to it
     */
    .matches(/^\+?\d{11}$|^$/u, VALIDATION_MESSAGES.phoneNumberValid),
  gender: yup.string().oneOf(Object.keys(Gender)).notRequired(),
  address: yup.string().notRequired(),
  class: yup.string().required(VALIDATION_MESSAGES.classRequired),
};

export const personalDetailsSchema = yup.object({
  ...familyMemberSchema,
});

export const personalDetailsSchemaForAdmin = (hasOnboardingPeriod: boolean) => {
  return {
    employee: yup
      .object()
      .shape({
        ...employeeSchemaForAdmin,
        ssn: hasOnboardingPeriod
          ? yup.string().matches(ssnRegex, {
              message: VALIDATION_MESSAGES.ssnNumberValid,
              excludeEmptyString: true,
            })
          : yup.string().notRequired(),
        isSmoker: hasOnboardingPeriod
          ? employeeSchemaForAdmin.isSmoker
          : yup.string().notRequired(),
        isPregnant: hasOnboardingPeriod
          ? employeeSchemaForAdmin.isPregnant
          : yup.string().notRequired(),
      })
      .required(),
  };
};

const insuredWithAnticipatedCareLevelSchema = (isChild = false) => {
  return {
    ...familyMemberSchema(isChild),
    anticipatedCareLevel: yup
      .string()
      .oneOf(
        Object.keys(AnticipatedCareLevel),
        VALIDATION_MESSAGES.anticipatedCareLevelRequired
      )
      .required(VALIDATION_MESSAGES.anticipatedCareLevelRequired),
  };
};
export const healthCareNeedsSchema = yup.object({
  employee: yup
    .object()
    .shape(insuredWithAnticipatedCareLevelSchema())
    .required(),
  spouse: yup
    .object()
    .shape(insuredWithAnticipatedCareLevelSchema())
    .notRequired()
    .default(undefined),
  dependents: yup
    .array()
    .of(
      yup.object().shape(insuredWithAnticipatedCareLevelSchema(true)).required()
    )
    .notRequired(),
});
